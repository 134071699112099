import { useQuery } from '@tanstack/react-query';
import { TParamsApiDof } from '_common/dof/interfaces';
import { useDebounce } from '_common/hooks/useDebounce';
import { forwardRef, useMemo, useState } from 'react';
import { OptionProps } from 'tera-dls';
import { SelectProps } from 'tera-dls/lib/components/Select';
import SelectEntity from '../SelectEntity';
import PaymentMethodAPI from 'states/api/PaymentMethodAPI';

interface ISelectAccountPaymentMethodProps extends SelectProps {
  paramsApi?: TParamsApiDof;
}

const SelectAccountPaymentMethod = forwardRef<
  HTMLInputElement,
  ISelectAccountPaymentMethodProps
>(({ placeholder = 'Vui lòng chọn', paramsApi, ...props }, ref) => {
  const [search, setSearch] = useState<string>('');
  const searchDebounce = useDebounce(search, 300);

  const { data: dataList, isLoading } = useQuery(
    ['get-user-account-payment-method-list', searchDebounce, paramsApi],
    () => {
      const params = {
        page: 1,
        limit: 15,
        keyword: searchDebounce,
        ...paramsApi,
      };
      return PaymentMethodAPI.getListOfAccount({ params });
    },
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const options: OptionProps[] = useMemo(
    () =>
      dataList?.data
        ?.filter((i: any) => i?.id)
        .map((data: any) => ({
          label: `${data?.type.name} - ${data?.type.description}`,
          value: data?.id,
        })) || [],
    [dataList],
  );

  return (
    <SelectEntity
      ref={ref}
      allowClear
      showSearch
      labelInValue
      loading={isLoading}
      placeholder={placeholder}
      options={options}
      searchValue={search}
      onSearch={setSearch}
      {...props}
    />
  );
});

export default SelectAccountPaymentMethod;
