import CardDetail from '_common/component/CardDetail';
import Text from '_common/component/Text';
import _ from 'lodash';
import { ACCOUNT_KYC_STATUS } from 'pages/Account/_common/constants';
import { useState } from 'react';
import { Button, Col, Row } from 'tera-dls';
import FormCreateUpdate from '../components/KYC/FormCreateUpdate';

interface IKYCProps {
  dataDetail: any;
}

const KYC = ({ dataDetail }: IKYCProps): JSX.Element => {
  const [isVerifyAgain, setIsVerifyAgain] = useState(false);

  return _.isEmpty(dataDetail?.kyc) || isVerifyAgain ? (
    <FormCreateUpdate dataDetail={dataDetail} />
  ) : (
    <Col className="flex flex-col items-start gap-4">
      <Row className="w-full grid grid-cols-3 gap-4">
        <CardDetail className="flex justify-center items-center">
          <Row className="max-w-[500px] max-h-[250px] flex">
            <img
              src={dataDetail?.kyc?.front_card_url}
              alt="Front Citizen ID Card"
              className="object-contain"
            />
          </Row>
        </CardDetail>
        <CardDetail className="flex justify-center">
          <Row className="max-w-[500px] max-h-[250px] flex">
            <img
              src={dataDetail?.kyc?.back_card_url}
              alt="Front Citizen ID Card"
              className="object-contain"
            />
          </Row>
        </CardDetail>
        <CardDetail className="flex justify-center">
          <Row className="max-w-[500px] max-h-[250px] flex">
            <img
              src={dataDetail?.kyc?.face_image_url}
              alt="Front Citizen ID Card"
              className="object-contain"
            />
          </Row>
        </CardDetail>
      </Row>
      {dataDetail?.kyc?.status === ACCOUNT_KYC_STATUS.NEW && (
        <Text className="text-yellow-500">
          * Đang tiếp nhận yêu cầu, Zing Cloud sẽ gửi email thông báo cho bạn
          kết quả xét duyệt
        </Text>
      )}
      {dataDetail?.kyc?.status === ACCOUNT_KYC_STATUS.APPROVAL && (
        <Text className="text-green-500">
          * Hồ sơ KYC của bạn đã được duyệt
        </Text>
      )}
      {dataDetail?.kyc?.status === ACCOUNT_KYC_STATUS.REFUSED && (
        <Text className="text-red-500">* Hồ sơ KYC của bạn đã bị từ chối</Text>
      )}
      <Button className="bg-blue-500" onClick={() => setIsVerifyAgain(true)}>
        Xác thực lại
      </Button>
    </Col>
  );
};

export default KYC;
