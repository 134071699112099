import NumberToWord from '_common/component/NumberToWord';
import Text from '_common/component/Text';
import { MoneyOptions } from '_common/constants/common';
import { messageValidate } from '_common/constants/message';
import InputNumber from '_common/dof/Control/InputNumber';
import { FormTeraItem } from '_common/dof/FormTera';
import { UseFormReturn } from 'react-hook-form';
import { Col, formatCurrency, Row } from 'tera-dls';

interface IFormDepositProps {
  form: UseFormReturn<any>;
}

const FormDeposit = ({ form }: IFormDepositProps): JSX.Element => {
  return (
    <Col className="flex flex-col gap-4">
      <Text>Nhập số tiền</Text>
      <Row className="flex gap-2 flex-wrap">
        {MoneyOptions.map((value, index) => (
          <span
            key={index}
            onClick={() =>
              form.setValue('total', form.getValues('total') + value)
            }
            className="p-2 border border-dotted rounded text-blue-500 cursor-pointer"
          >
            {formatCurrency(value)}
          </span>
        ))}
      </Row>
      <FormTeraItem
        name="total"
        className="mb-0"
        rules={[
          {
            min: {
              value: 50000,
              message: `${messageValidate.max_number}  ${formatCurrency(
                50000,
              )}`,
            },
            max: {
              value: 500000000,
              message: `${messageValidate.min_number} ${formatCurrency(
                500000000,
              )}`,
            },
          },
        ]}
      >
        <InputNumber
          className="text-right"
          suffix={<b>₫</b>}
          allowStepHandler={false}
        />
      </FormTeraItem>
      <Col className="flex flex-col gap-2">
        <Text>Quý khách lưu ý</Text>
        <ul className="list-decimal pl-8 flex flex-col gap-2">
          <li>Số tiền tối thiểu 50.000 ₫</li>
          <li>Số tiền tối đa 500.000.000 ₫</li>
          <li>
            Thanh toán sẽ tự động xác nhận trong khoảng 1 phút, nếu quá thời hạn
            trên vui lòng liên hệ CSKH để được hỗ trợ.
          </li>
        </ul>
      </Col>
      <Text>
        Bằng chữ:
        <span className="text-blue-500 ml-1 break-all">
          <NumberToWord number={form.watch('total')} isUpperCaseFirstLetter />.
        </span>
      </Text>
    </Col>
  );
};

export default FormDeposit;
