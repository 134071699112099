import ZingIcon from '_common/component/ZingIcon';

const menu = {
  groupMenu: [
    {
      id: 1,
      code: 'portal',
      title: 'ZING VPS',
      path: '',
      icon: '',
      key: 'portal',
      permission: '',
    },
  ],
  parentGroup: [
    {
      id: 1,
      title: 'Quản lý dịch vụ',
      key: 'service',
      parentKey: 'portal',
    },
    {
      id: 2,
      title: 'Quản lý hóa đơn',
      key: 'invoice',
      parentKey: 'portal',
    },
    {
      id: 3,
      title: 'Affiliates',
      key: 'affiliates',
      parentKey: 'portal',
    },
    {
      id: 4,
      title: 'Tài khoản của tôi',
      key: 'account',
      parentKey: 'portal',
    },
  ],
  parentMenu: [
    {
      id: 1,
      title: 'Tổng quan',
      path: '/dashboard',
      icon: '',
      iconNode: <ZingIcon.IconMenuHouse />,
      key: 'dashboard',
      parentKey: 'portal',
      parentGroupKey: '',
      permission: '',
    },
    /** Quản lý dịch vụ */
    {
      id: 2,
      title: 'Tên miền',
      path: '/service/domain/list',
      icon: '',
      iconNode: <ZingIcon.IconMenuWorld />,
      key: 'service-domain',
      parentKey: 'portal',
      parentGroupKey: 'service',
      permission: '',
    },
    {
      id: 3,
      title: 'Email và Bảo mật',
      path: '/service/email-security/list',
      icon: '',
      iconNode: <ZingIcon.IconMenuMail />,
      key: 'service-email-security',
      parentKey: 'portal',
      parentGroupKey: 'service',
      permission: '',
    },
    {
      id: 4,
      title: 'Web Hosting',
      path: '/service/hosting/list',
      icon: '',
      iconNode: <ZingIcon.IconMenuHosting />,
      key: 'service-hosting',
      parentKey: 'portal',
      parentGroupKey: 'service',
      permission: '',
    },
    {
      id: 5,
      title: 'Cloud Server',
      path: '/service/cloud-server/list',
      icon: '',
      iconNode: <ZingIcon.IconMenuDatabase />,
      key: 'service-cloud-server',
      parentKey: 'portal',
      parentGroupKey: 'service',
      permission: '',
    },
    {
      id: 6,
      title: 'SSL',
      path: '/service/ssl/list',
      icon: '',
      iconNode: <ZingIcon.IconMenuLock />,
      key: 'service-ssl',
      parentKey: 'portal',
      parentGroupKey: 'service',
      permission: '',
    },
    {
      id: 7,
      title: 'Cloud VPS',
      path: '/service/cloud-vps/list',
      icon: '',
      iconNode: <ZingIcon.IconMenuCloud />,
      key: 'service-cloud-vps',
      parentKey: 'portal',
      parentGroupKey: 'service',
      permission: '',
    },
    /** Quản lý hóa đơn */
    {
      id: 8,
      title: 'Giao dịch',
      path: '/invoice/transaction/list',
      icon: '',
      iconNode: <ZingIcon.IconMenuWifi />,
      key: 'invoice-transaction',
      parentKey: 'portal',
      parentGroupKey: 'invoice',
      permission: '',
    },
    {
      id: 9,
      title: 'Hóa đơn',
      path: '/invoice/invoice',
      icon: '',
      iconNode: <ZingIcon.IconMenuDollar />,
      key: 'invoice-invoice',
      parentKey: 'portal',
      parentGroupKey: 'invoice',
      permission: '',
    },
    {
      id: 10,
      title: 'Nạp tiền',
      path: '/invoice/deposit/list',
      icon: '',
      iconNode: <ZingIcon.IconMenuRecharge />,
      key: 'invoice-deposit',
      parentKey: 'portal',
      parentGroupKey: 'invoice',
      permission: '',
    },
    {
      id: 11,
      title: 'Rút tiền',
      path: '/invoice/withdrawal/list',
      icon: '',
      iconNode: <ZingIcon.IconMenuWithdrawal />,
      key: 'invoice-withdrawal',
      parentKey: 'portal',
      parentGroupKey: 'invoice',
      permission: '',
    },
    /** Affiliates */
    {
      id: 12,
      title: 'Tiếp thị liên kết',
      path: '/affiliates/marketing',
      icon: '',
      iconNode: <ZingIcon.IconMenuPersonPlus />,
      key: 'affiliates-marketing',
      parentKey: 'portal',
      parentGroupKey: 'affiliates',
      permission: '',
    },
    {
      id: 13,
      title: 'Hoa hồng của tôi',
      path: '/affiliates/commission/list',
      icon: '',
      iconNode: <ZingIcon.IconMenuCommission />,
      key: 'affiliates-commission',
      parentKey: 'portal',
      parentGroupKey: 'affiliates',
      permission: '',
    },
    {
      id: 14,
      title: 'Mã ưu đãi của tôi',
      path: '/affiliates/promo-code/list',
      icon: '',
      iconNode: <ZingIcon.IconMenuPromoCode />,
      key: 'affiliates-promo-code',
      parentKey: 'portal',
      parentGroupKey: 'affiliates',
      permission: '',
    },
    /** Tài khoản của tôi */
    {
      id: 15,
      title: 'Thông tin của tôi',
      path: '/account/my-information',
      icon: '',
      iconNode: <ZingIcon.IconMenuPerson />,
      key: 'account-my-information',
      parentKey: 'portal',
      parentGroupKey: 'account',
      permission: '',
    },
    {
      id: 16,
      title: 'Bảo mật',
      path: '/account/security',
      icon: '',
      iconNode: <ZingIcon.IconMenuShield />,
      key: 'account-security',
      parentKey: 'portal',
      parentGroupKey: 'account',
      permission: '',
    },
    {
      id: 17,
      title: 'Thiết lập bảo mật',
      path: '/account/setup-security',
      icon: '',
      iconNode: <ZingIcon.IconMenuSetting />,
      key: 'account-setup-security',
      parentKey: 'portal',
      parentGroupKey: 'account',
      permission: '',
    },
    {
      id: 18,
      title: 'Lịch sử',
      path: '/account/history/list',
      icon: '',
      iconNode: <ZingIcon.IconMenuWifi />,
      key: 'account-history',
      parentKey: 'portal',
      parentGroupKey: 'account',
      permission: '',
    },
    {
      id: 19,
      title: 'Đăng xuất',
      path: '/account/logout',
      icon: '',
      iconNode: <ZingIcon.IconMenuLogout />,
      key: 'account-logout',
      parentKey: 'portal',
      parentGroupKey: 'account',
      permission: '',
    },
  ],
  subMenu: [],
};

export default menu;
