import { useMutation, useQueryClient } from '@tanstack/react-query';
import ZingIcon from '_common/component/ZingIcon';
import { REGEX } from '_common/constants/common';
import { messageValidate } from '_common/constants/message';
import Input from '_common/dof/Control/Input';
import InputPassword from '_common/dof/Control/InputPassword';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import { useStores } from '_common/hooks';
import { CryptoJSAesEncrypt } from '_common/utils/hashHelper';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { AuthApi } from 'states/api';
import {
  Button,
  Col,
  EyeOutlined,
  EyeSlashOutlined,
  Row,
  Spin,
  notification,
  updateQueryParams,
} from 'tera-dls';
import { FORGOT_PASSWORD_URL } from '../../ForgotPassword/url';
import { REGISTER_URL } from '../../Register/url';

export interface ILoginValues {
  username: string;
  password: string;
}

const FormLogin = (): JSX.Element => {
  const form = useForm({
    mode: 'onChange',
    defaultValues: { username: '', password: '' },
  });
  const { authStore } = useStores();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(
    (params: any) => AuthApi.login({ params }),
    {
      onSuccess: (res) => {
        const bodyParams = {
          access_id: res?.data?.access_id,
        };
        const queryParams = updateQueryParams({
          client_id: 'tera',
          req: JSON.stringify(CryptoJSAesEncrypt(bodyParams)),
        });
        authStore.updateUser(res?.data);
        queryClient.invalidateQueries(['get-profile']);
        navigate(`/auth/check-auth${queryParams}`);
      },
      onError: (error: any) =>
        notification.error({
          message: error?.message,
        }),
    },
  );

  const handleLogin = (values: ILoginValues) => {
    if (isLoading) return;
    mutate(values);
  };

  return (
    <Spin spinning={isLoading}>
      <FormTera
        form={form}
        onSubmit={handleLogin}
        className="flex flex-col items-center gap-2"
      >
        <FormTeraItem
          className="w-full mb-0"
          name="username"
          rules={[{ required: messageValidate.emptyText }]}
        >
          <Input
            placeholder="Vui lòng nhập tài khoản"
            className="h-[52px] pl-[65px] border rounded-[10px]"
            prefix={
              <span className="flex items-center after:content-[''] after:w-[1px] after:h-[24px] after:bg-[#374151] after:ml-[10px]">
                <ZingIcon.IconLoginUser className="w-[24px] h-[24px]" />
              </span>
            }
          />
        </FormTeraItem>
        <FormTeraItem
          className="w-full mb-0"
          name="password"
          rules={[
            {
              required: messageValidate.emptyText,
              pattern: {
                value: new RegExp(REGEX.PASSWORD),
                message: messageValidate.password,
              },
            },
          ]}
        >
          <InputPassword
            placeholder="Vui lòng nhập mật khẩu"
            className="h-[52px] pl-[65px] border rounded-[10px]"
            prefix={
              <span className="flex items-center after:content-[''] after:w-[1px] after:h-[24px] after:bg-[#374151] after:ml-[10px]">
                <ZingIcon.IconLoginLock className="w-[24px] h-[24px]" />
              </span>
            }
            iconRender={(visible) =>
              visible ? (
                <EyeSlashOutlined className="w-[24px] h-[24px]" />
              ) : (
                <EyeOutlined className="w-[24px] h-[24px]" />
              )
            }
          />
        </FormTeraItem>
        <Row className="w-full flex justify-start">
          <Button
            htmlType="button"
            className="bg-transparent hover:bg-transparent p-0 cursor-pointer"
            onClick={() => navigate(FORGOT_PASSWORD_URL.index.path)}
          >
            <i className="text-[15px] text-[#005880] font-normal">
              Quên mật khẩu?
            </i>
          </Button>
        </Row>
        <Button
          htmlType="submit"
          type="primary"
          className="w-full h-[49px] text-[20px] font-bold bg-[#1975A7] rounded-[10px] mt-5"
          disabled={isLoading}
        >
          Đăng nhập
        </Button>
        <span className="text-[13px] text-[#005880] mt-2">hoặc</span>
        <Row className="mt-6 flex items-center gap-10">
          <Col>
            <Button className="bg-transparent hover:bg-transparent flex items-center gap-2">
              <ZingIcon.IconGoogle />
              <span className="text-black font-normal">Google</span>
            </Button>
          </Col>
          <Col>
            <Button className="bg-transparent hover:bg-transparent flex items-center gap-2">
              <ZingIcon.IconFacebook />
              <span className="text-black font-normal">Facebook</span>
            </Button>
          </Col>
          <Col>
            <Button
              className="bg-transparent hover:bg-transparent flex items-center gap-2"
              onClick={() => navigate(REGISTER_URL.index.path)}
            >
              <ZingIcon.IconBuildingColor />
              <span className="text-black font-normal">Đăng ký</span>
            </Button>
          </Col>
        </Row>
      </FormTera>
    </Spin>
  );
};

export default FormLogin;
