import { useMutation } from '@tanstack/react-query';
import DefaultImage from '_common/component/DefaultImage';
import ModalChangePassword from '_common/component/ModalChangePassword';
import TextNavigate from '_common/component/TextNavigate';
import ZingIcon from '_common/component/ZingIcon';
import { useStores } from '_common/hooks';
import { observer } from 'mobx-react-lite';
import { MY_INFORMATION_URL } from 'pages/Account/MyInformation/url';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthApi } from 'states/api';
import { EnvelopeOutlined, Popover, notification } from 'tera-dls';
import UserDefaultImage from 'styles/images/pages/User/UserDefaultImage.svg';

const User = (): JSX.Element => {
  const [modalResetPassword, setModalResetPassword] = useState<boolean>(false);
  const {
    authStore: { user, clear },
    commonStore: { clear: clearCRM },
  } = useStores();
  const navigate = useNavigate();

  const { mutate: onLogout } = useMutation(AuthApi.logout, {
    onSuccess: () => {
      clear();
      clearCRM();
      navigate('/welcome');
    },
    onError: (error: any) => {
      clear();
      clearCRM();
      const errorMessage = error?.msg;

      notification.error({
        message: errorMessage,
      });
    },
  });

  const Content = (): JSX.Element => {
    const classItem =
      'p-[6px] [&:not(:last-child)]:border-b hover:bg-gray-50 cursor-pointer';

    return (
      <div className="p-2.5 rounded-2.5 w-[200px] flex flex-col gap-y-5">
        <div className="flex flex-col gap-y-[14px]">
          <h3 className="text-blue-800">Tài khoản</h3>
          <div className="flex flex-col gap-y-[7px]">
            <div className="flex items-center gap-x-[7px]">
              <DefaultImage
                src={UserDefaultImage}
                alt={user?.full_name}
                className="size-10 rounded-full border shrink-0"
              />
              <div className="flex flex-col justify-center gap-x-[5px] w-full">
                <TextNavigate to={MY_INFORMATION_URL.list.path}>
                  {user?.full_name}
                </TextNavigate>
                <span className="text-[10px] text-gray-500 line-clamp-1 break-word">
                  {user?.role_name}
                </span>
              </div>
            </div>
            {user?.email && (
              <p className="flex items-center gap-x-[5px]">
                <EnvelopeOutlined className="size-4" />
                <span className="truncate">{user?.email}</span>
              </p>
            )}
          </div>
        </div>
        <div>
          <h3 className="text-blue-800 mb-[5px]">Khác</h3>
          <ul>
            <li
              className={classItem}
              onClick={() => setModalResetPassword(true)}
            >
              Đổi mật khẩu
            </li>
          </ul>
        </div>
        <div>
          <p
            className="flex gap-x-[5px] text-red-500 itemns-center p-[6px] hover:bg-gray-50 cursor-pointer"
            onClick={() => onLogout()}
          >
            <ZingIcon.IconExit />
            <span>Thoát</span>
          </p>
        </div>
      </div>
    );
  };

  return (
    <>
      <Popover
        trigger="click"
        content={<Content />}
        placement="bottom-end"
        showArrow={false}
        className="border"
      >
        <div
          className="cursor-pointer flex flex-col p-4 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:p-0 md:flex-row md:gap-2.5 md:items-center md:mt-0 md:border-0 md:bg-transparent dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700"
          id="user-menu-button"
          data-dropdown-placement="bottom"
          aria-expanded="false"
        >
          <DefaultImage
            src={UserDefaultImage}
            className="object-cover size-6 rounded-full border"
            alt="avatar-user"
          />
          <span className="text-[#374151]">{user?.full_name}</span>
        </div>
      </Popover>
      {modalResetPassword && (
        <ModalChangePassword
          userId={user?.id}
          open={modalResetPassword}
          onClose={() => setModalResetPassword(false)}
        />
      )}
    </>
  );
};

export default observer(User);
