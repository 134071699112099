import { useMutation } from '@tanstack/react-query';
import PageLoading from '_common/component/PageLoading';
import { useStores } from '_common/hooks';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthApi } from 'states/api';
import { notification } from 'tera-dls';

const LogoutPage = (): JSX.Element => {
  const {
    authStore: { clear },
    commonStore: { clear: clearCRM },
  } = useStores();
  const navigate = useNavigate();

  const { mutate: onLogout } = useMutation(AuthApi.logout, {
    onSuccess: () => {
      clear();
      clearCRM();
      navigate('/welcome');
    },
    onError: (error: any) => {
      clear();
      clearCRM();
      const errorMessage = error?.msg;

      notification.error({
        message: errorMessage,
      });
    },
  });

  useEffect(() => {
    onLogout();
  }, []);

  return <PageLoading />;
};

export default observer(LogoutPage);
